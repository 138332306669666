.App {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: var(--text-color);

  transition: background 0.3s ease-out;
}

.App-icon {
  width: 16px;
  height: fit-content;
  pointer-events: none;
  padding: 1rem;
}

@mixin outline($color) {
  $shadow: 0px 0px 1px $color;
  text-shadow: $shadow, $shadow, $shadow, $shadow, $shadow, $shadow;
}

.App-action-bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-link,
.App-icon-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: fit-content;
  height: 1rem;
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    background: var(--text-color);
    width: 0;
    height: 1px;
    position: absolute;
    bottom:0.7rem;
    margin: auto;

    transition: width 0.3s ease-out, background 0.3s ease-out;
  }

  &:hover::after {
    width: calc(100% - 1.6rem);
  }
}

.App-icon-link::after {
  display: none;
}

input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
}

svg {
  width: 100%;
  height: 100%;
  fill: var(--text-color);
}

.App-signature {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  margin: auto;
  width: fit-content;
  font-size: 12px;
  opacity: 0.2;
  transition: opacity 0.3s ease-out, color 0.3s ease-out;

  &:hover {
    opacity: 1;
  }
}

.App-sub-note {
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  font-size: 11px;

  .App-swatch {
    min-width: 12px;
    max-width: 12px;
    min-height: 12px;
    max-height: 12px;
    border: solid 1px var(--text-color);
  }
}
